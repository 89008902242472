<script>
import _ from 'lodash'

import AccountTransactionTable from '@/components/table/AccountTransactionTable/Index.vue'
import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  components: {
    AccountTransactionTable
  },
  props: {
    operationProp: String, // CREATE | UPDATE | DUPLICATE | DETAIL
    detailsProp: Array
  },
  data() {
    return {
      mode: 'VIEW',
      information: {
        totalCredit: 0,
        totalDebit: 0
      },
      details: []
    }
  },
  watch: {
    operationProp() {
      this.assignPropToData('mode')
    },
    detailsProp() {
      this.assignPropToData('details')
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.assignPropToData('mode')
      this.assignPropToData('details')
    },
    assignPropToData(attr) {
      switch (attr) {
        case 'mode':
          if (this.operationProp === 'DETAIL') this.mode = 'VIEW'
          else if (this.operationProp === 'DUPLICATE') this.mode = 'UPDATE'
          else this.mode = this.operationProp
          break
        case 'information':
          let totalCredit = 0
          let totalDebit = 0

          this.details.forEach(detail => {
            const { creditAmount, debitAmount } = detail

            if (!_.isNil(creditAmount)) {
              totalCredit += +creditAmount
            }
            if (!_.isNil(debitAmount)) {
              totalDebit += +debitAmount
            }
          })

          this.information = {
            totalCredit,
            totalDebit
          }
          break
        case 'details':
          if (this.detailsProp) {
            this.details = [...this.detailsProp]
            this.assignPropToData('information')
          }
          break
        default:
          break
      }
    },
    detailUpdatedHandler(e) {
      this.$emit('detailsUpdated', e)
    }
  }
}
</script>

<template>
  <div class="bg-light" style="border-radius: 10px;">
    <account-transaction-table
      :mode="mode"
      :informationProp="information"
      :detailsProp="details"
      @detailUpdated="detailUpdatedHandler"
    >
    </account-transaction-table>
  </div>
</template>

<style scoped></style>
