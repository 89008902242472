<script>
import _ from 'lodash'

import SelectJournalDocumentTemplateModal from '@/components/modal/SelectJournalDocumentTemplateModal'
import { toastMixins } from '@/mixins'
import ge2gjnlService from '@/services/entry/ge2gjnl'

export default {
  mixins: [toastMixins],
  components: {
    SelectJournalDocumentTemplateModal
  },
  props: {
    operationProp: String, // DETAIL | CREATE | UPDATE | DUPLICATE
    headerProp: Object,
    isLoadingProp: Boolean
  },
  data() {
    return {
      isLoading: false,
      header: {
        templateCode: null,
        templateName: null,
        userIdGroupCode: null,
        journalCode: null
      },
      userIdGroupCodeOptions: [],
      journalCodeOptions: [],
      selectedTemplates: [],
      errMsg: null,
      // select journal document template modal
      displaySelectTemplateModal: false
    }
  },
  watch: {
    headerProp() {
      this.assignPropToData('header')
    },
    header: {
      handler(val) {
        this.$emit('headerUpdated', this.header)
      },
      deep: true
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.assignPropToData('header')

      await Promise.all([
        this.fetchUserIdGroupCodeOptions(),
        this.fetchJournalCodeOptions()
      ])
    },
    assignPropToData(attr) {
      switch (attr) {
        case 'header':
          this.header = { ...this.headerProp }
          break
        default:
          break
      }
    },
    async fetchUserIdGroupCodeOptions() {
      this.isLoading = true
      try {
        const res = await ge2gjnlService.getUserIdGroupCodeOptions()
        this.userIdGroupCodeOptions = [...res.data.data]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงตัวเลือกกลุ่มผู้ใช้งานไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    async fetchJournalCodeOptions() {
      this.isLoading = true
      try {
        const res = await ge2gjnlService.getJournalBookOptions()
        this.journalCodeOptions = [...res.data.data]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงตัวเลือกสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    copyButtonClicked() {
      this.displaySelectTemplateModal = true
    },
    cancelButtonClicked() {
      this.$router.push('/entry/ge2gjnl/journal-document-templates')
    },
    confirmButtonClicked() {
      this.$emit('confirmButtonClicked')
    },
    modalClosedHandler() {
      this.displaySelectTemplateModal = false
    },
    templateSelectedHandler(template) {
      this.selectedTemplates.push(template)
      this.$emit('templateSelected', template)
    }
  }
}
</script>

<template>
  <div class="bg-light p-4" style="border-radius: 10px;">
    <!-- templateCode / templateName -->
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="d-flex align-items-center px-3 px-xl-5 my-2"
      >
        <span class="text-nowrap mr-3">
          รหัสต้นแบบ
        </span>
        <b-input
          :disabled="
            isLoadingProp ||
              isLoading ||
              ['DETAIL', 'UPDATE'].includes(operationProp)
          "
          v-model="header.templateCode"
        ></b-input>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="d-flex align-items-center px-3 px-xl-5 my-2"
      >
        <span class="text-nowrap mr-3">
          คำอธิบาย
        </span>
        <b-input
          :disabled="isLoadingProp || isLoading || operationProp === 'DETAIL'"
          v-model="header.templateName"
        ></b-input>
      </b-col>
    </b-row>

    <!-- userIdGroupCode / journalBook -->
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="d-flex align-items-center px-3 px-xl-5 my-2"
      >
        <span class="text-nowrap mr-3">
          กลุ่มผู้ใช้งาน
        </span>
        <b-form-select
          :disabled="isLoadingProp || isLoading || operationProp === 'DETAIL'"
          v-model="header.userIdGroupCode"
          :options="userIdGroupCodeOptions"
        ></b-form-select>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="d-flex align-items-center px-3 px-xl-5 my-2"
      >
        <span class="text-nowrap mr-3">
          สมุดรายวัน
        </span>
        <b-form-select
          :disabled="isLoadingProp || isLoading || operationProp === 'DETAIL'"
          v-model="header.journalCode"
          :options="journalCodeOptions"
        ></b-form-select>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr v-if="operationProp !== 'DETAIL'" />

    <!-- buttons -->
    <div
      v-if="operationProp !== 'DETAIL'"
      class="d-flex justify-content-center justify-content-xl-between "
    >
      <div class="pl-xl-4 d-flex align-items-center">
        <b-button
          class="mr-3"
          variant="outline-primary"
          @click="copyButtonClicked()"
          :disabled="isLoadingProp || isLoading"
        >
          <b-spinner
            v-if="isLoadingProp || isLoading"
            class="mx-4 mx-xl-5"
            variant="primary"
            small
          ></b-spinner>
          <span v-else class="mx-4 mx-xl-5 text-nowrap">
            คัดลอก
          </span>
        </b-button>
        <span class="text-secondary  d-none d-xl-flex">{{
          selectedTemplates.length > 0
            ? `${selectedTemplates[selectedTemplates.length - 1].templateCode}`
            : ''
        }}</span>
      </div>
      <div class="pr-xl-4">
        <b-button
          class="mr-3"
          variant="outline-primary"
          @click="cancelButtonClicked()"
          :disabled="isLoadingProp || isLoading"
        >
          <b-spinner
            v-if="isLoadingProp || isLoading"
            class="mx-4 mx-xl-5"
            variant="primary"
            small
          ></b-spinner>
          <span v-else class="mx-4 mx-xl-5">
            ยกเลิก
          </span>
        </b-button>
        <b-button
          variant="primary"
          @click="confirmButtonClicked()"
          :disabled="isLoadingProp || isLoading"
        >
          <b-spinner
            v-if="isLoadingProp || isLoading"
            class="mx-4 mx-xl-5"
            variant="light"
            small
          ></b-spinner>
          <span v-else class="mx-4 mx-xl-5">
            ยืนยัน
          </span>
        </b-button>
      </div>
    </div>

    <!-- select journal document template modal component -->
    <select-journal-document-template-modal
      :displayModalProp="displaySelectTemplateModal"
      @modalClosed="modalClosedHandler"
      @templateSelected="templateSelectedHandler"
    ></select-journal-document-template-modal>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
